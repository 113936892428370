import { d, GaFrom } from "@/lib/core";
import {
  fmf,
  getCurrentPregnancy,
  getGaAt,
  getLastFinishedPregnancy,
  getPregnancyGA,
  getEstimatedDeliveryDate,
} from "@/lib/core";
import { getStudiesData } from "@/utils";
import {
  type DopplerProvider,
  type Patient,
  type WeightProvider,
} from "@/types";
import usePractitionerPrefs from "./usePractitionerPrefs";

interface Props {
  patient?: Patient | null;
  pregnancyEndDate?: Date | null;
  weightProvider?: WeightProvider;
  dopplerProvider?: DopplerProvider;
}

export const getPatientData = ({
  patient,
  pregnancyEndDate,
  weightProvider,
  dopplerProvider,
}: Props) => {
  if (!patient) return {};
  const currentPregnancy = getCurrentPregnancy(patient?.pregnancies ?? []);
  const lastFinishedPreganancy = getLastFinishedPregnancy(patient?.pregnancies);
  const pregnancy = currentPregnancy ?? lastFinishedPreganancy;

  const isPregnancyFinished = !!pregnancy?.endDate;

  const pregnancyFinishDate = isPregnancyFinished ? pregnancy?.endDate : null;

  const ga = getPregnancyGA(pregnancy!, patient?.studies);

  const gaToday = getGaAt(ga, d().toISOString());
  const gaTodayAsDays = fmf.obstetricWeeksObjectToDaysNumber(gaToday);
  const gaTodayOW = fmf.daysToOW(gaTodayAsDays);

  const getPatientGaAt = (
    date: string | Date,
    format: "obstetricWeeks" | "days" = "obstetricWeeks"
  ) => {
    const gaAsdays = fmf.obstetricWeeksObjectToDaysNumber(getGaAt(ga, date));
    if (format === "days") return gaAsdays;
    return fmf.daysToOW(gaAsdays);
  };

  const gaAtFinishDate = pregnancyFinishDate
    ? getGaAt(ga, d(pregnancyFinishDate).toISOString())
    : null;
  const gaAtFinishDateAsDays =
    fmf.obstetricWeeksObjectToDaysNumber(gaAtFinishDate);
  const gaAtFinishDateOW = fmf.daysToOW(gaAtFinishDateAsDays);

  const studiesData = getStudiesData(
    patient?.studies,
    ga,
    pregnancy,
    weightProvider,
    dopplerProvider
  );

  // GET EFW today
  type efwToday = {
    efw: number;
    gaAsDays: number | null;
    efwCentile: number | null;
  };
  const gtEfwFromStudy = studiesData?.reduce<efwToday>(
    (acc: efwToday, st) => {
      const efw = st.fmfEFW;
      const efwCentile = st.efwCentile;
      const gaAsDays = st.gaAtStudyAsDaysNumber;
      if (efw && efw > acc?.efw) {
        return { efw, gaAsDays, efwCentile };
      }
      return acc;
    },
    { efw: 0, gaAsDays: 0, efwCentile: 0 }
  );

  const centileStudy = weightProvider
    ? weightProvider.centileFromFW(
        gtEfwFromStudy?.efw,
        gtEfwFromStudy?.gaAsDays
      )
    : null;

  const calcEFWAt = (date: string | Date) => {
    const ga = getPatientGaAt(date, "days");
    return weightProvider ? weightProvider.calcEFW(ga, centileStudy) : null;
  };

  const fwToday = weightProvider
    ? weightProvider.calcEFW(gaTodayAsDays, centileStudy)
    : null;

  const fwTodayCentile = weightProvider
    ? weightProvider.centileFromFW(fwToday, gaTodayAsDays)
    : null;

  const fumc =
    ga && ga.from !== GaFrom.lmp && ga?.at
      ? d(ga.at)
          .subtract(ga?.weeks ?? 0, "weeks")
          .subtract(ga?.days ?? 0, "days")
      : null;
  const estimatedDeliveryDate = getEstimatedDeliveryDate(fumc);

  // GA atada al valor del input finish-date del modal de finalización.
  const gaAtPregnancyEndDate = pregnancyEndDate
    ? fmf.obstetricWeeksObjectToDaysNumber(
        getGaAt(ga, d(pregnancyEndDate).toISOString())
      )
    : null;

  return {
    currentPregnancy,
    lastFinishedPreganancy,
    pregnancy,
    isPregnancyFinished,
    pregnancyFinishDate,
    ga,
    gaToday,
    gaTodayAsDays,
    gaTodayOW,
    gaAtFinishDate,
    gaAtFinishDateAsDays,
    gaAtFinishDateOW,
    studiesData,
    gtEfwFromStudy,
    centileStudy,
    fwToday,
    fwTodayCentile,
    fumc,
    gaAtPregnancyEndDate,
    estimatedDeliveryDate,
    lastEFWCentile: gtEfwFromStudy?.efwCentile,
    getPatientGaAt,
    calcEFWAt,
  };
};

export type PatientData = ReturnType<typeof getPatientData>;
export const usePatientData = ({ patient, pregnancyEndDate }: Props) => {
  const { weightProvider, dopplerProvider } = usePractitionerPrefs();
  return getPatientData({
    patient,
    pregnancyEndDate,
    weightProvider,
    dopplerProvider,
  });
};
