import {
  Flex,
  Box,
  Button,
  Fieldset,
  Grid,
  NumberInput,
  TextInput,
  Checkbox,
  useMantineTheme,
} from "@mantine/core";
import { type Patient } from "@/types";
import { type UnitNum } from "@/types";
import { useAuth } from "@clerk/nextjs";
import { d } from "@/lib/core";
import { DatePickerInput } from "@mantine/dates";
import { languageTag } from "@/paraglide/runtime";
import { fmf } from "@/lib/core";
import * as m from "@/paraglide/messages";
import { UnitNumInput } from "@/components/form/UnitNumInput";
import { useMediaQuery } from "@mantine/hooks";
import { type Dayjs } from "dayjs";
interface State {
  ecoAge: number | null;
  ipAutIz?: number | null;
  ipAutDer?: number | null;
  ipAU?: number | null;
  ipDV?: number | null;
  ipACM?: number | null;
  crl?: UnitNum | null;
  hc?: UnitNum | null;
  ac?: UnitNum | null;
  fl?: UnitNum | null;
  efw?: UnitNum | null;
  fumc?: string | null;
  gaFromHc?: boolean;
  fechaEco?: string | null;
  showResultsPage?: boolean;
  gaFrom?: string | null;
  operator?: string;
}

interface InputProps {
  state: State;
  ga: number | null;
  update: (obj: Partial<State>) => void;
  queryPatientId: string | undefined;
  isFirstStudy: boolean;
  initialValues: State;
  queryFumc?: Dayjs | null;
  patient: Patient | undefined | null;
  isLoading: boolean;
  saveStudy: () => void;
  hasData: boolean;
  ready: boolean;
}
export const Inputs = ({
  state,
  ga,
  update,
  queryPatientId,
  isFirstStudy,
  initialValues,
  queryFumc,
  patient,
  isLoading,
  saveStudy,
  hasData,
  ready,
}: InputProps) => {
  const { isSignedIn } = useAuth();
  const isHigher = Boolean(state.ecoAge && state.ecoAge >= 14);
  const isLower = Boolean(state.ecoAge && state.ecoAge < 14);
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.md}`);

  return (
    <Box
      bd={patient ? "" : "1px solid lightgray"}
      p={patient ? 0 : "sm"}
      style={{ borderRadius: 4 }}
    >
      <Grid>
        <Grid.Col span={{ sm: 12, md: 12 }} order={{ base: 1, md: 1 }}>
          <DatePickerInput
            locale={languageTag()}
            tabIndex={2}
            clearable
            maxDate={d().toDate()}
            valueFormat="DD/MM/YYYY"
            label={m.free_quiet_jaguar_surge()}
            value={state.fechaEco ? d(state.fechaEco).toDate() : null}
            onChange={(value) => {
              const newState = {
                fechaEco: value ? d(value).toISOString() : value,
                ecoAge: state.ecoAge,
              };
              if (!value) {
                newState.ecoAge = null;
              }
              update(newState);
            }}
            popoverProps={{
              returnFocus: true,
              position: "bottom-start",
              middlewares: {
                flip: false,
                shift: true,
              },
            }}
            dropdownType={isMobile ? "modal" : "popover"}
          />
        </Grid.Col>
        <Grid.Col
          hidden={!state.fechaEco || Boolean(queryFumc)}
          span={{ sm: 12, md: 12 }}
          order={{ base: 2, md: 2 }}
        >
          <Fieldset legend={m.bald_misty_okapi_lift()} p="sm">
            <Flex gap="md" wrap="wrap">
              <Checkbox
                label={m.fair_knotty_panther_reside()}
                checked={Boolean(state.ecoAge && state.ecoAge < 14)}
                onChange={(e) => {
                  const { fechaEco, ...rest } = initialValues;
                  update({
                    ...rest,
                    ecoAge: e.currentTarget.checked ? 12 : 15,
                  });
                }}
                styles={{
                  label: {
                    paddingLeft: 4,
                  },
                }}
              />
              <Checkbox
                checked={Boolean(state.ecoAge && state.ecoAge > 14)}
                label={m.curly_grand_mayfly_trim()}
                onChange={(e) => {
                  const { fechaEco, ...rest } = initialValues;
                  update({
                    ...rest,
                    ecoAge: e.currentTarget.checked ? 15 : 12,
                  });
                }}
                styles={{
                  label: {
                    paddingLeft: 4,
                  },
                }}
              />
            </Flex>
          </Fieldset>
        </Grid.Col>
        <Grid.Col
          hidden={!ready || isLower || !!queryPatientId}
          span={{ sm: 12, md: 12 }}
          order={{ base: 3, md: 3 }}
        >
          <DatePickerInput
            locale={languageTag()}
            autoFocus
            tabIndex={1}
            clearable
            maxDate={d(state.fechaEco).subtract(14, "weeks").toDate()}
            minDate={d(state.fechaEco).subtract(42, "weeks").toDate()}
            disabled={!!queryPatientId && !!queryFumc && Boolean(state.fumc)}
            valueFormat="DD/MM/YYYY"
            label={m.lmpC()}
            value={state.fumc ? d(state.fumc).toDate() : null}
            onChange={(value) => {
              update({
                gaFromHc: false,
                fumc: value ? d(value).toISOString() : value,
              });
            }}
            error={
              state?.gaFromHc &&
              state?.fumc && (
                <span style={{ color: "black" }}>{m.calcFromHC()}</span>
              )
            }
            styles={
              state?.gaFromHc && state?.fumc && !!queryPatientId
                ? { input: { borderColor: "#ffbebe" } }
                : {}
            }
            popoverProps={{
              returnFocus: true,
              position: "bottom-start",
              middlewares: {
                flip: false,
                shift: true,
              },
            }}
            dropdownType={isMobile ? "modal" : "popover"}
          />
        </Grid.Col>
        <Grid.Col
          hidden={!ready || isHigher}
          span={{ sm: 12, md: 12 }}
          order={{ base: 4, md: 4 }}
        >
          <UnitNumInput
            inputMode="decimal"
            tabIndex={2}
            allowedDecimalSeparators={[".", ","]}
            allowNegative={false}
            allowUnitChange={false}
            description={`${m.large_flaky_walrus_advise()}: 1-84`}
            label={m.crl()}
            defaultUnit="mm"
            updateValueOnUnitChange
            value={state.crl}
            onChange={(value) => {
              update({ crl: value });
            }}
            error={state?.crl?.value && state.crl.value > 84}
          />
        </Grid.Col>
        <Grid.Col
          hidden={!ready || isLower}
          span={{ sm: 12, md: 12 }}
          order={{ base: 5, md: 5 }}
        >
          <Fieldset legend={m.grand_busy_insect_swim()} p="xs" bg="transparent">
            <Flex gap="xs" justify="space-between">
              <Box w="100%">
                <UnitNumInput
                  inputMode="decimal"
                  tabIndex={3}
                  allowedDecimalSeparators={[".", ","]}
                  allowNegative={false}
                  allowUnitChange={false}
                  label={m.HC()}
                  defaultUnit="mm"
                  updateValueOnUnitChange
                  value={state.hc}
                  onChange={(value) => update({ hc: value })}
                  error={state?.hc?.value && state.hc.value > 499}
                  withAsterisk={Boolean(
                    patient &&
                      isFirstStudy &&
                      state?.ecoAge &&
                      state?.ecoAge > 14
                  )}
                />
              </Box>
              <Box w="100%">
                <UnitNumInput
                  inputMode="decimal"
                  tabIndex={4}
                  allowedDecimalSeparators={[".", ","]}
                  allowNegative={false}
                  allowUnitChange={false}
                  // description={`${t("range")}: 1-499`}
                  label={m.AC()}
                  defaultUnit="mm"
                  updateValueOnUnitChange
                  value={state.ac}
                  onChange={(value) => update({ ac: value })}
                  error={state?.ac?.value && state.ac.value > 499}
                />
              </Box>
              <Box w="100%">
                <UnitNumInput
                  inputMode="decimal"
                  tabIndex={5}
                  allowedDecimalSeparators={[".", ","]}
                  allowNegative={false}
                  allowUnitChange={false}
                  // description={`${t("range")}: 1-99`}
                  label={m.FL()}
                  defaultUnit="mm"
                  updateValueOnUnitChange
                  value={state.fl}
                  onChange={(value) => update({ fl: value })}
                  error={state?.fl?.value && state.fl.value > 99}
                />
              </Box>
            </Flex>
          </Fieldset>
        </Grid.Col>
        <Grid.Col
          hidden={!ready || isLower}
          span={{ sm: 12, md: 12 }}
          order={{ base: 6, md: 6 }}
        >
          <UnitNumInput
            inputMode="decimal"
            tabIndex={6}
            allowedDecimalSeparators={[".", ","]}
            allowUnitChange={false}
            label={m.loved_round_deer_jolt()}
            defaultUnit="g"
            updateValueOnUnitChange
            value={state?.efw}
            parseValue={(value) => (value ? Number(value.toFixed(4)) : value)}
            onChange={(value) => update({ efw: value })}
          />
        </Grid.Col>

        <Grid.Col
          hidden={!ready || !ga || ga < fmf.MIN_GA_TO_UTERINE_ARTERY_CENTILE}
          span={{ sm: 12, md: 12 }}
          order={{ base: 7, md: 7 }}
        >
          <Flex gap={20} justify="space-between">
            <NumberInput
              inputMode="decimal"
              tabIndex={7}
              allowedDecimalSeparators={[".", ","]}
              hideControls
              label={m.leftUterineArteryPulsatilityIndex()}
              description={m.next_any_orangutan_compose({ weeksNumber: 11 })}
              value={String(state.ipAutIz)}
              onChange={(value) => update({ ipAutIz: Number(value) })}
              w="100%"
            />
            <NumberInput
              inputMode="decimal"
              tabIndex={8}
              allowedDecimalSeparators={[".", ","]}
              hideControls
              label={m.rightUterineArteryPulsatilityIndex()}
              description={m.next_any_orangutan_compose({ weeksNumber: 11 })}
              value={String(state.ipAutDer)}
              onChange={(value) => update({ ipAutDer: Number(value) })}
              w="100%"
            />
          </Flex>
        </Grid.Col>

        <Grid.Col
          hidden={
            !ready ||
            isLower ||
            !ga ||
            ga < fmf.MIN_GA_TO_UMBILICAL_ARTERY_CENTILE
          }
          span={{ sm: 12, md: 12 }}
          order={{ base: 8, md: 8 }}
        >
          <NumberInput
            inputMode="decimal"
            tabIndex={9}
            allowedDecimalSeparators={[".", ","]}
            hideControls
            label={m.umbilicalArteryPulsatilityIndex()}
            value={String(state.ipAU)}
            onChange={(value) => update({ ipAU: Number(value) })}
          />
        </Grid.Col>

        <Grid.Col
          hidden={
            !ready ||
            isLower ||
            !ga ||
            ga < fmf.MIN_GA_TO_MIDDLE_CEREBRAL_CENTILE
          }
          span={{ sm: 12, md: 12 }}
          order={{ base: 9, md: 9 }}
        >
          <NumberInput
            inputMode="decimal"
            tabIndex={10}
            allowedDecimalSeparators={[".", ","]}
            hideControls
            label={m.middleCerebralArteryPI()}
            value={String(state.ipACM)}
            onChange={(value) => update({ ipACM: Number(value) })}
          />
        </Grid.Col>

        <Grid.Col
          hidden={!ready || isLower || !ga}
          span={{ sm: 12, md: 12 }}
          order={{ base: 10, md: 10 }}
        >
          <NumberInput
            inputMode="decimal"
            tabIndex={11}
            allowedDecimalSeparators={[".", ","]}
            hideControls
            label={m.doctusVenosusPI()}
            value={String(state.ipDV)}
            onChange={(value) => update({ ipDV: Number(value) })}
          />
        </Grid.Col>
        {isSignedIn && queryPatientId && (
          <Grid.Col
            hidden={!ready}
            span={{ sm: 12, md: 12 }}
            order={{ base: 11, md: 11 }}
          >
            <TextInput
              tabIndex={12}
              description={m.tasty_lower_wombat_spark()}
              value={state.operator}
              onChange={(event) => update({ operator: event.target.value })}
              w="100%"
            />
          </Grid.Col>
        )}
      </Grid>

      {patient && state.ecoAge ? (
        <Box mt="lg">
          <Button
            loading={isLoading}
            // size="compact"
            variant="primary"
            onClick={saveStudy}
            fullWidth
            radius="md"
            disabled={!hasData}
          >
            {m.trite_sad_stingray_work()}
          </Button>
        </Box>
      ) : null}
    </Box>
  );
};
