import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ActionIcon, Box, Group, Text, Tooltip } from "@mantine/core";
import { type Patient } from "@/types";
import { type State } from "./types";
import * as m from "@/paraglide/messages";
import { fmf, intergrowth, getEstimatedDeliveryDate, d } from "@/lib/core";
import { un } from "@/utils";
import { notifications } from "@mantine/notifications";
import { useMutation } from "@tanstack/react-query";
import { StudySubType, StudyType } from "@/lib/db";
import { createStudy } from "@/utils/fetchers";
import { usePatientData } from "@/hooks/usePatientData";
import { Inputs } from "./Inputs";
import Results from "./Results";
import { IconRefresh } from "@tabler/icons-react";
import { queryClient } from "@/utils/client/queries/client";
import { USE_PRACTITIONER_PATIENTS_QUERY_KEY } from "@/utils/client/queries/keys";
import usePractitionerPrefs from "@/hooks/usePractitionerPrefs";

export function Calc({
  patient,
  practitionerId,
  refetch,
  resultsTop = 49,
  blockSaveMessage = "",
}: {
  patient?: Patient | null | undefined;
  practitionerId?: string | null;
  refetch?: () => void;
  resultsTop?: number;
  blockSaveMessage?: string;
}) {
  const {
    getPatientGaAt,
    fumc: defaultFumc,
    studiesData: studies,
  } = usePatientData({
    patient,
  });
  const { dopplerProvider } = usePractitionerPrefs();
  const queryFumc = defaultFumc;
  const queryPatientId = patient?.id;

  const initialValues = {
    ecoAge: null,
    ipAutIz: null,
    ipAutDer: null,
    ipAU: null,
    ipACM: null,
    ipDV: null,
    crl: null,
    hc: null,
    ac: null,
    fl: null,
    efw: null,
    fechaEco: null,
    fumc: queryFumc ? d(queryFumc).toString() : null,
    gaFromHc: false,
    gaFrom: null,
    showResultsPage: false,
    operator: "",
  };

  const [ready, setReady] = useState(false);
  const [state, setState] = React.useState<State>({
    ...initialValues,
  });

  const update = useCallback((obj: Partial<State>) => {
    setState((prev) => ({ ...prev, ...obj }));
  }, []);

  const ga = useMemo(() => {
    const crl = state?.crl;
    const hc = state?.hc;
    const fumc = state?.fumc;
    const ecoDate = state?.fechaEco && new Date(state.fechaEco);

    if (patient && queryFumc && getPatientGaAt) {
      update({ gaFromHc: false, gaFrom: "fumc" });
      return getPatientGaAt(d(state.fechaEco).toString(), "days");
    }

    const gaFromCRL = fmf.gaFromCrl(un(crl)?.to("mm").toNumber() ?? 0, 1);
    const gaFromFUMc = fumc && ecoDate ? fmf.gaFromLmp(fumc, ecoDate) : null;
    const gaFromHC = fmf.gaFromHc(un(hc)?.to("mm").toNumber() ?? 0);

    if (gaFromCRL) {
      update({ gaFromHc: false, gaFrom: "crl" });
      // console.log("ga from CRL");
      return !isNaN(gaFromCRL) && gaFromCRL > 0 ? gaFromCRL : null;
    }

    if (gaFromFUMc && state?.ecoAge && state.ecoAge > 14) {
      if (!state.gaFromHc) {
        update({ gaFromHc: false, gaFrom: "fumc" });
      }
      if (!state.gaFromHc) {
        // console.log("ga from FUMc");
        return !isNaN(gaFromFUMc) && gaFromFUMc > 0 ? gaFromFUMc : null;
      }
    }

    if (gaFromHC) {
      // console.log("ga from HC");
      update({ gaFromHc: true, gaFrom: "hc" });
      return !isNaN(gaFromHC) && gaFromHC > 0 ? gaFromHC : null;
    }

    // console.log("ga from null");
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.crl, state?.hc, state?.fumc, state?.fechaEco]);

  const gaObject = ga ? fmf.daysToOWObject(ga) : null;
  const relCerPlacentaria = fmf.calcCPR(state?.ipACM, state?.ipAU);
  const efwP = fmf.centileFromFW(state?.efw?.value, ga);
  const efwCentileINTERGROUTH = intergrowth.centileFromFW(
    state?.efw?.value,
    ga
  );
  const ipAutP =
    state?.ipAutIz && state?.ipAutDer
      ? dopplerProvider.uterineArteryPICentile(
          ((state?.ipAutIz ?? 0) + (state?.ipAutDer ?? 0)) / 2,
          Math.round(ga ?? 0) ?? null
        )
      : null;
  const ipAUP = dopplerProvider.umbilicalArteryPICentile(state.ipAU, ga ?? 0);
  const ipACMP = dopplerProvider.middleCerebralPICentile(state.ipACM, ga ?? 0);
  const ipDVP = dopplerProvider.doctusVenosusPICentile(state.ipDV, ga ?? 0);
  const cerebroPlacentalRatioCentile =
    dopplerProvider.cerebroPlacentalRatioCentile(relCerPlacentaria, ga);

  const hcCentile = fmf.centileFromHC(state?.hc?.value, ga);
  const acCentile = fmf.centileFromAC(state?.ac?.value, ga);
  const flCentile = fmf.centileFromFL(state?.fl?.value, ga);
  // defino si está la info para guardar la eco
  const hasData = !!ga;
  const isFirstStudy = !studies || studies?.length === 0;

  const { mutate, isLoading } = useMutation({
    mutationFn: createStudy,
    mutationKey: ["create-patient-study"],
    onSuccess: () => {
      notifications.show({
        message: m.neat_mild_fly_jolt(),
      });
      void queryClient.invalidateQueries({
        queryKey: [USE_PRACTITIONER_PATIENTS_QUERY_KEY],
      });
      if (refetch && typeof refetch === "function") refetch();
      update(initialValues);
    },
    onError: (error) => {
      console.log(error);
      notifications.show({
        message: m.wise_late_salmon_buy(),
        color: "red",
      });
    },
  });

  useEffect(() => {
    // Update EFW
    const hc = un(state.hc)?.to("mm").toNumber();
    const ac = un(state.ac)?.to("mm").toNumber();
    const fl = un(state.fl)?.to("mm").toNumber();
    const weight = fmf.calculateEFWFromBiometry({ hc, ac, fl }) as
      | number
      | null
      | undefined;

    if (weight) {
      update({ efw: { value: weight, unit: "g" } });
    } else {
      update({ efw: null });
    }
  }, [state.hc, state.ac, state.fl]);

  useEffect(() => {
    if (getPatientGaAt) {
      const patientGa = state.fechaEco ? getPatientGaAt(state.fechaEco) : null;
      update({ ecoAge: patientGa });
    }
  }, [state.fechaEco]);

  useEffect(() => {
    // ommit if patientFumc
    if (queryFumc) return;
    // UPDATE fumc from study inputs
    if (!!state?.ecoAge) {
      if (state.ecoAge > 14) {
        if (gaObject && state?.fechaEco) {
          const result = d(state?.fechaEco)
            .subtract(gaObject.weeks, "weeks")
            .subtract(gaObject.days ?? 0, "days");

          update({ fumc: d(result).toString() });
        }
        if (state.gaFromHc) {
          if (state?.fumc && !state?.hc) {
            update({ fumc: null });
          } else if (!ga) {
            update({ fumc: null });
          }
        }
      } else if (state.ecoAge < 14) {
        if (ga && gaObject && state?.fechaEco) {
          const result = d(state?.fechaEco)
            .subtract(gaObject.weeks, "weeks")
            .subtract(gaObject.days ?? 0, "days");
          update({ fumc: d(result).toString() });
        } else if (!ga || !state?.fechaEco) {
          update({ fumc: null });
        }
      }
    }
  }, [state?.hc, state?.fechaEco, state?.crl, ga, state?.ecoAge]);

  const gaToday = useMemo(() => {
    if (ga && state?.fechaEco) {
      const ecoDate = d(state.fechaEco);
      const today = d();

      const diffDays = ecoDate.diff(today, "days");

      return fmf.daysToOW(ga + Math.abs(diffDays));
    }
    return null;
  }, [ga, state?.fechaEco]);

  useEffect(() => {
    if (!queryPatientId && state.ecoAge && state?.gaFromHc) {
      notifications.show({
        color: "yellow",
        message: m.last_cute_baboon_dart(),
      });
    }
  }, [state?.ecoAge, state?.gaFromHc]);

  useEffect(() => {
    if (
      isFirstStudy &&
      !!patient &&
      state?.ecoAge &&
      state?.ecoAge > 14 &&
      !state.hc
    ) {
      notifications.show({
        color: "red",
        message: m.still_alert_jellyfish_find(),
      });
    }
  }, [isFirstStudy, state?.ecoAge, state.ac]);

  const saveStudy = () => {
    if (blockSaveMessage) {
      notifications.show({
        message: blockSaveMessage,
        color: "yellow",
      });
      return;
    }
    const studyData = {
      rightUterinePulsatilityIndex: state.ipAutDer,
      leftUterinePulsatilityIndex: state.ipAutIz,
      umbilicalArteryPulsatilityIndex: state.ipAU,
      middleCerebralArteryPulsatilityIndex: state.ipACM,
      ductusVenosusPulsatilityIndex: state.ipDV,
      HC: state.hc,
      AC: state.ac,
      FL: state.fl,
      EFW: state.efw,
      CRL: state.crl,
      operator: state.operator,
      fumc: d(state?.fumc).toISOString(),
    };
    const data = {
      body: {
        data: studyData,
        date: state.fechaEco,
        subType: StudySubType.obstetricUltrasound,
        type: StudyType.pregnancy,
        practitionerId,
      },
      patientId: patient?.id,
    };
    mutate(data);
  };

  useEffect(() => {
    if (!!state.ecoAge && !!state.fechaEco) {
      setReady(true);
    } else {
      setReady(false);
    }
  }, [state.ecoAge, state.fechaEco]);

  return (
    <Box>
      {state.ecoAge && !patient && (
        <Group justify="space-between">
          <Text size="14px" mb="sm">
            {state.ecoAge >= 14
              ? m.odd_careful_beetle_fulfill()
              : m.loose_fluffy_niklas_persist()}
          </Text>
          <Tooltip ml="auto" label={m.ago_bold_fireant_intend()}>
            <ActionIcon
              variant="transparent"
              onClick={() => {
                update({ ...initialValues });
                setReady(false);
              }}
            >
              <IconRefresh />
            </ActionIcon>
          </Tooltip>
        </Group>
      )}
      <Results
        ga={ga}
        gaToday={gaToday}
        state={state}
        ipAutP={ipAutP}
        hcCentile={hcCentile}
        acCentile={acCentile}
        flCentile={flCentile}
        efwP={efwP}
        efwCentileINTERGROUTH={efwCentileINTERGROUTH}
        ipAUP={ipAUP}
        ipACMP={ipACMP}
        cerebroPlacentalRatioCentile={cerebroPlacentalRatioCentile}
        ipDVP={ipDVP}
        my="sm"
        patient={patient}
        estimatedDeliveryDate={getEstimatedDeliveryDate(state?.fumc)}
        resultsTop={resultsTop}
      />
      <Inputs
        ga={ga}
        update={update}
        queryPatientId={queryPatientId}
        state={state}
        isFirstStudy={isFirstStudy}
        initialValues={initialValues}
        queryFumc={queryFumc}
        patient={patient}
        isLoading={isLoading}
        saveStudy={saveStudy}
        hasData={hasData}
        ready={ready}
      />
    </Box>
  );
}
