import {
  Flex,
  Box,
  Text,
  Divider,
  Grid,
  type FlexProps,
  em,
} from "@mantine/core";
import { d } from "@/lib/core";
import { useMediaQuery } from "@mantine/hooks";
import { fmf } from "@/lib/core";
import { useRef } from "react";
import { type Patient } from "@/types";
import { type State } from "./types";
import type dayjs from "dayjs";
import * as m from "@/paraglide/messages";
import { languageTag } from "@/paraglide/runtime";

export const KeyValue = ({
  k,
  v,
  danger = false,
}: {
  k: string;
  v: string | number | null | undefined;
  danger?: boolean;
}) => {
  const isMobile = useMediaQuery(`(max-width: ${em(750)})`);
  if (!k && !v) return null;
  return (
    <Flex
      align="baseline"
      columnGap={isMobile ? 2 : 6}
      c={danger ? "red" : "black"}
    >
      {k && (
        <Text fz={isMobile ? 12 : 14} fw={isMobile ? 400 : 300}>
          {k}
          {":"}
        </Text>
      )}
      <Text fz={isMobile ? 12 : 14} fw={600}>
        {v}
      </Text>
    </Flex>
  );
};

interface Props extends FlexProps {
  ga: number | null;
  gaToday: number | null;
  state: State;
  ipAutP: number | null;
  hcCentile?: number | null;
  acCentile?: number | null;
  flCentile?: number | null;
  efwP?: number | null;
  efwCentileINTERGROUTH?: number | null;
  ipAUP?: number | null;
  ipACMP?: number | null;
  cerebroPlacentalRatioCentile?: number | null;
  ipDVP?: number | null;
  patient: Patient | undefined | null;
  estimatedDeliveryDate: dayjs.Dayjs | null;
  resultsTop: number;
}

export default function Results({
  ga,
  gaToday,
  state,
  ipAutP,
  hcCentile,
  acCentile,
  flCentile,
  efwP,
  efwCentileINTERGROUTH,
  ipAUP,
  ipACMP,
  cerebroPlacentalRatioCentile,
  ipDVP,
  patient,
  estimatedDeliveryDate,
  resultsTop,
  ...flexProps
}: Props) {
  //   const [scroll, scrollTo] = useWindowScroll();
  const ref = useRef(null);
  const lang = languageTag();
  //   const visible = isVisible(ref?.current?.getBoundingClientRect(), scroll.y);
  //   console.log("visible", visible);
  if (!ga && !gaToday && !state?.fumc && !ipAutP) return null;
  // const pinned = useHeadroom({ fixedAt: 14 });
  //   const { ref, inViewport } = useInViewport();
  //   console.log(inViewport);
  const keys = {
    gaToday: "gaToday",
    ga: "ga",
    fumc: "fumc",
    edd: "edd",
    hcCentile: "hcCentile",
    acCentile: "acCentile",
    flCentile: "flCentile",
    efwP: "efwP",
    efwCentileINTERGROUTH: "efwCentileINTERGROUTH",
    ipAutP: "ipAutP",
    ipAUP: "ipAUP",
    ipACMP: "ipACMP",
    cerebroPlacentalRatioCentile: "cerebroPlacentalRatioCentile",
    ipDVP: "ipDVP",
  };
  const values = [
    {
      key: keys.gaToday,
      value: gaToday,
      label: m.fluffy_still_mare_reap(),
      empty: !gaToday,
    },
    {
      key: keys.ga,
      value: fmf.daysToOW(ga),
      label: m.nimble_alive_camel_drop(),
      empty: !ga,
    },
    {
      key: keys.fumc,
      value: d(state?.fumc).format(lang === "en" ? "MM/DD/YYYY" : "DD/MM/YYYY"),
      label: m.lmpC(),
      empty: !state?.fumc,
    },
    {
      key: keys.edd,
      value: d(estimatedDeliveryDate).format(
        lang === "en" ? "MM/DD/YYYY" : "DD/MM/YYYY"
      ),
      label: m.EDD(),
      empty: !estimatedDeliveryDate,
    },
    {
      key: keys.hcCentile,
      value: hcCentile,
      label: m.HCp(),
      empty: !hcCentile,
    },
    {
      key: keys.acCentile,
      value: acCentile,
      label: m.ACp(),
      empty: !acCentile,
    },
    {
      key: keys.flCentile,
      value: flCentile,
      label: m.FLp(),
      empty: !flCentile,
    },
    {
      key: keys.efwP,
      value: efwP,
      label: m.arable_weird_camel_pat(),
      empty: !efwP,
      danger: efwP !== null && efwP !== undefined && (efwP > 95 || efwP < 5),
    },
    {
      key: keys.efwCentileINTERGROUTH,
      value: efwCentileINTERGROUTH,
      label: m.yummy_fit_slug_promise(),
      empty: !efwCentileINTERGROUTH,
      danger:
        efwCentileINTERGROUTH !== null &&
        efwCentileINTERGROUTH !== undefined &&
        (efwCentileINTERGROUTH > 95 || efwCentileINTERGROUTH < 5),
    },
    {
      key: keys.ipAutP,
      value: ipAutP,
      label: m.mealy_strong_crow_link(),
      empty: !ipAutP,
      danger: ipAutP !== null && ipAutP !== undefined && ipAutP > 95,
    },
    {
      key: keys.ipAUP,
      value: ipAUP,
      label: m.next_quiet_tortoise_bubble(),
      empty: !ipAUP,
      danger: ipAUP !== undefined && ipAUP !== null && ipAUP > 95,
    },
    {
      key: keys.ipACMP,
      value: ipACMP,
      label: m.watery_less_shark_fall(),
      empty: !ipACMP,
      danger: ipACMP !== null && ipACMP !== undefined && ipACMP < 5,
    },
    {
      key: keys.cerebroPlacentalRatioCentile,
      value: cerebroPlacentalRatioCentile,
      label: m.helpful_just_wolf_assure(),
      empty: !cerebroPlacentalRatioCentile,
      danger:
        cerebroPlacentalRatioCentile !== null &&
        cerebroPlacentalRatioCentile !== undefined &&
        cerebroPlacentalRatioCentile < 5,
    },
    {
      key: keys.ipDVP,
      value: ipDVP,
      label: m.wild_new_ray_foster(),
      empty: !ipDVP,
      danger: ipDVP !== null && ipDVP !== undefined && ipDVP > 95,
    },
  ];
  const getRowValues = (keys: string[]) => {
    return values.filter(({ key }) => {
      return keys.includes(key);
    });
  };
  const getReportValues = () => {
    if (state?.ecoAge) {
      if (state.ecoAge >= 14) {
        if (!patient) {
          // sin paciente + 14
          return {
            rows: [
              {
                id: 1,
                values: getRowValues([
                  keys.gaToday,
                  keys.ga,
                  keys.fumc,
                  keys.edd,
                ]),
                isEmpty:
                  !gaToday && !ga && !state?.fumc && !estimatedDeliveryDate,
              },
              {
                id: 2,
                values: getRowValues([
                  keys.hcCentile,
                  keys.acCentile,
                  keys.flCentile,
                  keys.efwP,
                  keys.efwCentileINTERGROUTH,
                ]),
                isEmpty:
                  !hcCentile &&
                  !acCentile &&
                  !flCentile &&
                  !efwP &&
                  !efwCentileINTERGROUTH,
              },
              {
                id: 3,
                values: getRowValues([
                  keys.ipAutP,
                  keys.ipAUP,
                  keys.ipACMP,
                  keys.cerebroPlacentalRatioCentile,
                  keys.ipDVP,
                ]),
                isEmpty:
                  !ipAutP &&
                  !ipAUP &&
                  !ipACMP &&
                  !cerebroPlacentalRatioCentile &&
                  !ipDVP,
              },
            ],
          };
        } else {
          // con paciente + 14
          return {
            rows: [
              {
                id: 4,
                values: getRowValues([keys.ga]),
                isEmpty: !gaToday && !state?.fumc && !estimatedDeliveryDate,
              },
              {
                id: 5,
                values: getRowValues([
                  keys.hcCentile,
                  keys.acCentile,
                  keys.flCentile,
                  keys.efwP,
                  keys.efwCentileINTERGROUTH,
                ]),
                isEmpty:
                  !hcCentile &&
                  !acCentile &&
                  !flCentile &&
                  !efwP &&
                  !efwCentileINTERGROUTH,
              },
              {
                id: 6,
                values: getRowValues([
                  keys.ipAutP,
                  keys.ipAUP,
                  keys.ipACMP,
                  keys.cerebroPlacentalRatioCentile,
                  keys.ipDVP,
                ]),
                isEmpty:
                  !ipAutP &&
                  !ipAUP &&
                  !ipACMP &&
                  !cerebroPlacentalRatioCentile &&
                  !ipDVP,
              },
            ],
          };
        }
      }
      if (state.ecoAge < 14) {
        if (!patient) {
          // sin paciente - 14
          return {
            rows: [
              {
                id: 7,
                values: getRowValues([
                  keys.gaToday,
                  keys.ga,
                  keys.fumc,
                  keys.edd,
                ]),
                isEmpty:
                  !gaToday && !ga && !state?.fumc && !estimatedDeliveryDate,
              },
              {
                id: 8,
                values: getRowValues([keys.ipAutP]),
                isEmpty: !ipAutP,
              },
            ],
          };
        } else {
          // con paciente - 14
          return {
            rows: [
              {
                id: 9,
                values: getRowValues([keys.ga, keys.fumc, keys.edd]),
                isEmpty: !ga && !state?.fumc && !estimatedDeliveryDate,
              },
              {
                id: 10,
                values: getRowValues([keys.ipAutP]),
                isEmpty: !ipAutP,
              },
            ],
          };
        }
      }
    }
    return {
      rows: [],
    };
  };

  const reportValues = getReportValues();
  const emptyReport = reportValues?.rows.every((c) => c?.isEmpty);

  if (emptyReport) return null;
  return (
    <Box
      ref={ref}
      p="xs"
      bg="indigo.0"
      style={{ borderRadius: 5, zIndex: 90 }}
      // pos={isMobile ? "sticky" : "initial"}
      pos={"sticky"}
      // top={isMobile ? "48px" : "60px"}
      top={resultsTop}
      left="0"
      right="0"
      {...flexProps}
    >
      <Grid>
        {reportValues?.rows.map((row, idx) => {
          if (row.isEmpty) return null;
          return (
            <div key={row.id} style={{ width: "100%" }}>
              {idx !== 0 && (
                <Grid.Col p="0">
                  <Divider />
                </Grid.Col>
              )}
              <Grid.Col span={12}>
                <Flex wrap="wrap" columnGap={10} rowGap={2}>
                  {row.values.map((col) => {
                    if (col.empty) return null;
                    return (
                      <KeyValue
                        key={col.key}
                        k={col.label}
                        v={col.value}
                        danger={col?.danger}
                      />
                    );
                  })}
                </Flex>
              </Grid.Col>
            </div>
          );
        })}
      </Grid>
    </Box>
  );
}
