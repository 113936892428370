import { type UnitNum } from "@/types";
import {
  type NumberInputProps,
  NumberInput,
  ActionIcon,
  Text,
  Menu,
} from "@mantine/core";
import { un } from "@/utils";
import { useMemo } from "react";
// import {
//   IconSettings,
//   IconSearch,
//   IconPhoto,
//   IconMessageCircle,
//   IconTrash,
//   IconArrowsLeftRight,
// } from "@tabler/icons-react";
interface Props extends Omit<NumberInputProps, "value" | "onChange"> {
  value?: UnitNum | null;
  onChange: (value?: UnitNum | null) => void;
  defaultUnit?: string;
  updateValueOnUnitChange?: boolean;
  allowUnitChange?: boolean;
  parseValue?: (value?: number) => number | undefined;
}

export const UnitNumInput: React.FC<Props> = ({
  value,
  defaultUnit,
  onChange,
  updateValueOnUnitChange = false,
  allowUnitChange = true,
  parseValue = (v) => v,
  ...rest
}) => {
  const unit = value?.unit ?? defaultUnit ?? "cm";
  const n = useMemo(
    () => un({ value: value?.value, unit }),
    [value?.value, unit]
  );
  const handleUnitChange = (newUnit: string) => {
    const newNumber = updateValueOnUnitChange
      ? n?.to(newUnit).toNumber()
      : n?.toNumber();

    onChange({
      value: newNumber ?? 0,
      unit: newUnit,
    });
  };

  return (
    <NumberInput
      {...rest}
      hideControls
      value={value ? parseValue(n?.toNumber()) : ""}
      rightSection={
        <UnitPicker
          unit={unit}
          onChange={handleUnitChange}
          allowUnitChange={allowUnitChange}
        />
      }
      rightSectionWidth="fit-content"
      styles={{
        input: {
          paddingLeft: 10,
        },
      }}
      onChange={(e) => {
        if (!e) return onChange(null);
        onChange({
          value: Number(e),
          unit: unit,
        });
      }}
    />
  );
};

interface UnitPickerProps {
  unit?: string;
  onChange: (newUnit: string) => void;
  allowUnitChange?: boolean;
}
const UnitPicker: React.FC<UnitPickerProps> = (props) => {
  const unit = props.unit;
  if (!unit) return null;
  const section = unitsMap.find((unitSection) => {
    if (unitSection.values.some((u) => u.key === unit)) return unitSection;
  });
  if (!section) return null;
  return (
    <Menu shadow="md">
      <Menu.Target>
        <ActionIcon
          tabIndex={-1}
          variant="default"
          w="fit-content"
          px={2}
          mr={2}
        >
          <Text size="xs" c="gray">
            {unit}
          </Text>
        </ActionIcon>
      </Menu.Target>
      {props.allowUnitChange ? (
        <Menu.Dropdown>
          <Menu.Label>{section.label}</Menu.Label>
          {section.values.map(({ key, label }) => {
            return (
              <Menu.Item key={key} onClick={() => props.onChange(key)}>
                {label} ({key})
              </Menu.Item>
            );
          })}
        </Menu.Dropdown>
      ) : null}
    </Menu>
  );
};

const unitsMap = [
  {
    label: "Masa",
    values: [
      { label: "ton", key: "tonne" },
      { label: "kilogram", key: "kg" },
      { label: "gram", key: "g" },
      { label: "miligram", key: "mg" },
    ],
  },
  {
    label: "Longitud",
    values: [
      { label: "kilometer", key: "km" },
      { label: "meter", key: "m" },
      { label: "centimeter", key: "cm" },
      { label: "millimeter", key: "mm" },
      { label: "inch", key: "in" },
      { label: "foot", key: "ft" },
      { label: "yard", key: "yd" },
      { label: "mile", key: "mi" },
    ],
  },
  {
    label: "Tiempo",
    values: [
      { label: "milisegundo", key: "ms" },
      { label: "segundo", key: "s" },
      { label: "minuto", key: "min" },
      { label: "hora", key: "hr" },
      { label: "día", key: "day" },
      { label: "mes", key: "month" },
      { label: "año", key: "year" },
    ],
  },
];
